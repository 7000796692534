import * as _readableStream2 from "readable-stream";

var _readableStream = "default" in _readableStream2 ? _readableStream2.default : _readableStream2;

import _buffer from "buffer";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var Buffer = _buffer.Buffer;
  exports.base64 = true;
  exports.array = true;
  exports.string = true;
  exports.arraybuffer = typeof ArrayBuffer !== "undefined" && typeof Uint8Array !== "undefined";
  exports.nodebuffer = typeof Buffer !== "undefined"; // contains true if JSZip can read/generate Uint8Array, false otherwise.

  exports.uint8array = typeof Uint8Array !== "undefined";

  if (typeof ArrayBuffer === "undefined") {
    exports.blob = false;
  } else {
    var buffer = new ArrayBuffer(0);

    try {
      exports.blob = new Blob([buffer], {
        type: "application/zip"
      }).size === 0;
    } catch (e) {
      try {
        var Builder = self.BlobBuilder || self.WebKitBlobBuilder || self.MozBlobBuilder || self.MSBlobBuilder;
        var builder = new Builder();
        builder.append(buffer);
        exports.blob = builder.getBlob("application/zip").size === 0;
      } catch (e) {
        exports.blob = false;
      }
    }
  }

  try {
    exports.nodestream = !!_readableStream.Readable;
  } catch (e) {
    exports.nodestream = false;
  }

  return exports;
}