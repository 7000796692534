import * as _lie2 from "lie";

var _lie = "default" in _lie2 ? _lie2.default : _lie2;

var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // load the global object first:
  // - it should be better integrated in the system (unhandledRejection in node)
  // - the environment may have a custom Promise implementation (see zone.js)
  var ES6Promise = null;

  if (typeof Promise !== "undefined") {
    ES6Promise = Promise;
  } else {
    ES6Promise = _lie;
  }
  /**
   * Let the user use/change some implementations.
   */


  exports = {
    Promise: ES6Promise
  };
  return exports;
}