import { dew as _GenericWorkerDew } from "./GenericWorker";
import { dew as _crcDew } from "../crc32";
import { dew as _utilsDew } from "../utils";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var GenericWorker = _GenericWorkerDew();

  var crc32 = _crcDew();

  var utils = _utilsDew();
  /**
   * A worker which calculate the crc32 of the data flowing through.
   * @constructor
   */


  function Crc32Probe() {
    GenericWorker.call(this, "Crc32Probe");
    this.withStreamInfo("crc32", 0);
  }

  utils.inherits(Crc32Probe, GenericWorker);
  /**
   * @see GenericWorker.processChunk
   */

  Crc32Probe.prototype.processChunk = function (chunk) {
    this.streamInfo.crc32 = crc32(chunk.data, this.streamInfo.crc32 || 0);
    this.push(chunk);
  };

  exports = Crc32Probe;
  return exports;
}