import { dew as _utilsDew } from "../utils";
import { dew as _supportDew } from "../support";
import { dew as _ArrayReaderDew } from "./ArrayReader";
import { dew as _StringReaderDew } from "./StringReader";
import { dew as _NodeBufferReaderDew } from "./NodeBufferReader";
import { dew as _Uint8ArrayReaderDew } from "./Uint8ArrayReader";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var utils = _utilsDew();

  var support = _supportDew();

  var ArrayReader = _ArrayReaderDew();

  var StringReader = _StringReaderDew();

  var NodeBufferReader = _NodeBufferReaderDew();

  var Uint8ArrayReader = _Uint8ArrayReaderDew();
  /**
   * Create a reader adapted to the data.
   * @param {String|ArrayBuffer|Uint8Array|Buffer} data the data to read.
   * @return {DataReader} the data reader.
   */


  exports = function (data) {
    var type = utils.getTypeOf(data);
    utils.checkSupport(type);

    if (type === "string" && !support.uint8array) {
      return new StringReader(data);
    }

    if (type === "nodebuffer") {
      return new NodeBufferReader(data);
    }

    if (support.uint8array) {
      return new Uint8ArrayReader(utils.transformTo("uint8array", data));
    }

    return new ArrayReader(utils.transformTo("array", data));
  };

  return exports;
}