import { dew as _Uint8ArrayReaderDew } from "./Uint8ArrayReader";
import { dew as _utilsDew } from "../utils";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var Uint8ArrayReader = _Uint8ArrayReaderDew();

  var utils = _utilsDew();

  function NodeBufferReader(data) {
    Uint8ArrayReader.call(this, data);
  }

  utils.inherits(NodeBufferReader, Uint8ArrayReader);
  /**
   * @see DataReader.readData
   */

  NodeBufferReader.prototype.readData = function (size) {
    this.checkOffset(size);
    var result = this.data.slice(this.zero + this.index, this.zero + this.index + size);
    this.index += size;
    return result;
  };

  exports = NodeBufferReader;
  return exports;
}